import React from 'react'

function LatestMatch() {
  return (
	 <section className="latest-match-area latest-match-bg pt-115 pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three white-title text-center mb-70">
                <h2>Despre <span>Duggano Security</span></h2>
                
              </div>
            </div>
          </div>
          <div className="row despreduggano">
            <div className="col-lg-8">
              <div className="text-formular">
                  <p>
                  Are ca scop oferirea de servicii de top in domeniul pazei si protectiei, servicii dedicate in principal companiilor.Misiunea noastra este de a asigura atat securitatea, dar si eficientizarea continua, astfel incat sa diminuam riscul unei situatii de criza pentru fiecare dintre partenerii nostri. </p>
              </div>
            </div>
            <div className="col-lg-4" >
              <img src="assets/img/images/caracter1.png" alt=""/>
            </div>
           
          </div>
          
          <div className="row paddingtop despreduggano">
            <div className="col-lg-4">
              <img src="assets/img/images/caracter2.png" alt=""/>
            </div>
            <div className="col-lg-8">
              <div className="text-formular">
                  <p>
                  Viziunea noastra este legata de calitatea serviciilor oferite, astfel incat acestea sa fie imaginea conceptului VIP. Felul in care expunem asta se observa incepand de la aspectul agentilor, la cunostintele acestora, de la echipamentele utilizate, la modul de actiune. </p>
                 
              </div>
            </div>
            
           
          </div>

          <div className="row paddingtop">
           
            <div className="col-lg-12">
              <div className="text-formular">
                <p></p>
                <h4>                                             Servicii de paza oferite cu profesionalism</h4>
                Securitatea este un subiect pe care trebuie sa il tratezi mereu cu maxima seriozitate. Indiferent ca este vorba despre siguranta ta sau a familiei tale, daca ai nevoie de servicii de paza si protectie, cu certitudine iti doresti sa colaborezi exclusiv cu profesionisti. Firma de securitate ideala este cea care iti ofera, pe langa garantia experientei, si o comunicare eficienta. Criteriile pe care trebuie sa le bifeze o astfel de companie pentru a merita sa ii acorzi increderea ta sunt clare si extrem de importante. Iata care sunt reperele decisive de care trebuie sa tii cont inainte sa alegi cine are grija de siguranta ta!
                   
                <p></p> <h4>Unde ne gasiti?</h4>
                    <p>Birourile Alta Street, Los Santos (fosta scoala de soferi)</p>
                   <center><img src="assets/img/locatie.png" alt=""/></center> 
                    </div>
            </div>
         
           
           
          </div>
        
         
        </div>
      </section>
  )
}

export default LatestMatch
import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';

// Sample event data
const events = [
  {
    title: 'Kart Race Duggano',
    prize: '150.000 $, 100.000 $, 50.000 $',
    winner: 'Statica, Arturo, Spoitoru',
    date: '2024-06-27',
    thumbnail: 'assets/img/evenimente/event27-06-2024.png',
    photos: [
     'assets/img/evenimente/event27-06-2024.png',
      'assets/img/evenimente/27-06-2024-1.png',
      'assets/img/evenimente/27-06-2024-2.png',
      'assets/img/evenimente/27-06-2024-3.png',
      'assets/img/evenimente/27-06-2024-4.png',
      'assets/img/evenimente/27-06-2024-5.png',
      'assets/img/evenimente/27-06-2024-6.png'
    ],
    status: 'Finalizat', // Add status here
  },
  {
    title: 'Tombola DeClasse',
    prize: 'Declasse Vigero ZX',
    winner: 'Manea Ionut',
    date: '2024-06-23',
    thumbnail: 'assets/img/evenimente/tombola23-06-2024.png',
    photos: [
     'assets/img/evenimente/tombola23-06-2024.png',
     'assets/img/evenimente/23-06-2024-1.png',
      'assets/img/evenimente/23-06-2024-2.png',
      'assets/img/evenimente/23-06-2024-3.png',
      'assets/img/evenimente/23-06-2024-4.png',
    ],
    status: 'Finalizat', // Add status here
  },
  {
    title: 'Tombola Grotti',
    prize: 'Grotti Cheetah',
    winner: 'Willow Kumar',
    date: '2024-06-20',
    thumbnail: 'assets/img/evenimente/tombola20-06-2024.png',
    photos: [
      'assets/img/evenimente/tombola20-06-2024.png',
      'assets/img/evenimente/20-06-2024-1.png',
      'assets/img/evenimente/20-06-2024-2.png',
      'assets/img/evenimente/20-06-2024-3.png',
      'assets/img/evenimente/20-06-2024-4.png',
    ],
    status: 'Finalizat', // Add status here
  },
 
  // Add more events as needed
];

// Custom styles for the modal
const customStyles = {
  content: {
    top: '60%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80%',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

function EvenimenteContent() {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal(event) {
    setSelectedEvent(event);
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
    setSelectedEvent(null);
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: null, // Hide the previous arrow
    nextArrow: null, // Hide the next arrow
  };

  return (
    <section className="latest-match-area latest-match-bg pt-115 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title title-style-three white-title text-center mb-70">
              <h2>Evenimente <span>Duggano Security</span></h2>
            </div>
          </div>
        </div>
        <div className="row">
          {events.map((event, index) => (
            <div className="col-md-4" key={index}>
              <div className="card dark-card" onClick={() => openModal(event)}>
                <img src={event.thumbnail} alt={event.title} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title">{event.title}</h5>
                  <p className="card-text">Premiu: {event.prize}</p>
                  <p className="card-text">Castigator: {event.winner}</p>
                  <p className="card-text">Data: {event.date}</p>
                  <span className={`badge ${event.status === 'Finalizat' ? 'badge-success' : 'badge-warning'}`}>
                    {event.status}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Event Details"
      >
        {selectedEvent && (
          <>
            <h2>{selectedEvent.title}</h2>
            <Slider {...sliderSettings}>
              {selectedEvent.photos.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Gallery ${index}`} className="img-fluid" />
                </div>
              ))}
            </Slider>
            <p>Premiu: {selectedEvent.prize}</p>
            <p>Castigator: {selectedEvent.winner}</p>
            <p>Data: {selectedEvent.date}</p>
          </>
        )}
        <button onClick={closeModal}>Inchide</button>
      </Modal>
    </section>
  );
}

export default EvenimenteContent;

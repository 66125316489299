import React from 'react';
import HeaderThree from '../../components/header/HeaderThree';
import FooterThree from '../../components/footer/FooterThree';
import GalerieContent from './galerie-content';
import GameGallery from './GameGallery'
function Galerie() {
  return (
	<>
	<HeaderThree/>
	<main>
	

	
	<GameGallery/>
	</main>
	<FooterThree/>
	</>
  )
}

export default Galerie

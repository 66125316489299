import React,{ useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import ScrollToTop from "react-scroll-to-top";


function HeaderThree() {

  useEffect(()=>{
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }
      

  },[]);
  useEffect(()=>{
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');
    
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });
    
  },[])
  return (

	 <header className="third-header-bg">
     <ScrollToTop smooth top="500" color="#000" />
          <div className="bg" />
          <div className="container custom-container">
            <div className="header-top-area t-header-top-area d-none d-lg-block">
              <div className="row">
                <div className="col-sm-6">
                  <div className="header-top-login">
                  <ul style={{ justifyContent: 'flex-start', important: 'true' }}>
                    <li><a href="/depunere-cv"><i className="far fa-file" />Depune CV</a></li>
                    <li style={{ paddingLeft: '10px'}}> <i className="fa fa-phone"/> 307-8705 </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="header-top-login">
                    <ul>
                
                      <li><a href="/#"><i className="far fa-user" />Acces membri</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div id="sticky-header" className="menu-area">
            <div className="container custom-container">
              <div className="row">
                <div className="col-12">
                 <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
                  <div className="main-menu  menu-style-two">
                    <nav>
                      <div className="logo d-block d-lg-none">
                        <a href="/"><img src="assets/img/logo/logosimplu.gif" class="logo-mobile" alt="Logo" /></a>
                      </div>
                      <div className="navbar-wrap d-none d-lg-flex">
                        <ul className="left">
                          <li><Link to="/">Home</Link>
                           
                          </li>
                          <li><Link to="/servicii">Servicii</Link></li>

                          
                        <li><Link to="/evenimente">Evenimente</Link></li>
                        </ul>
                        <div className="logo">
                          <Link to="/"><img src="assets/img/logo/logo1.gif" alt="Logo" /></Link>
                        </div>
                        <ul className="right">
                          
                          
                          <li><Link to="/galerie">Galerie</Link></li>
                          <li><Link to="/cerere">Contract paza</Link></li>
                         <li><Link to="/despre-noi">Despre noi</Link></li>
                          
                        </ul>
                      </div>
                      
                    </nav>
                  </div>
                  <div className="mobile-menu-wrap main-menu d-block d-lg-none">
                    <nav>
                      <div  className="navbar-wrap push-menu">
                        <ul className="navigation">
                         
                            <li><Link to="/">Home</Link></li>
                             
                          <li><Link to="/servicii">Servicii</Link></li>
                          <li><Link to="/evenimente">Evenimente</Link></li>
                          <li><Link to="/galerie">Galerie</Link></li>
                          
                          <li><Link to="/cerere">Contract paza</Link></li>
                         
                          <li><Link to="/despre-noi">Despre noi</Link></li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                    {/* mobile-menu           */}
                  <div className="mobile-menu">
                      <nav className="menu-box">
                          <div className="close-btn"><i className="fas fa-times" /></div>
                          <div className="nav-logo"><Link to="/"><img src="assets/img/logo/logo1.png" alt="" title='true' /></Link>
                          </div>
                          <div className="menu-outer">
                        
                          </div>
                         
                      </nav>
                </div>
              
               </div>
             
              </div>
            </div>
          </div>
          <div className="header-bottom-bg" />
        </header>
  )
}

export default HeaderThree
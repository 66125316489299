import React from 'react';
import HeaderThree from '../../components/header/HeaderThree';
import FooterThree from '../../components/footer/FooterThree';
import EvenimenteContent from './evenimente-content';


function Evenimente() {
  return (
	<>
	<HeaderThree/>
	<main>
	<EvenimenteContent/>



	</main>
	<FooterThree/>
	</>
  )
}

export default Evenimente

import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import axios from 'axios';

function CvContent() {
    const [formData, setFormData] = useState({
        nume: '',
        prenume: '',
        cnp: '',
        telefon: '',
    });
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const dataToSend = { ...formData };

        axios.post('https://apidug.flum.ro:8089/api/cvform', dataToSend, { withCredentials: true })
            .then(response => {
                console.log('Form data sent successfully:', response.data);
                setSuccessMessage('CV-ul dvs. a fost depus cu succes!');
                setFormData({
                    nume: '',
                    prenume: '',
                    cnp: '',
                    telefon: '',
                });
            })
            .catch(error => {
                console.error('Error sending form data:', error);
            });
    };

    return (
        <section className="latest-match-area latest-match-bg pt-115 pb-90">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="section-title title-style-three white-title text-center mb-70">
                            <h2>Depunere <span>CV</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-formular">
                            <h4>Depunere CV</h4>
                            <p>
                                Daca sunteti pasionat de domeniul securitatii si doriti sa va alaturati echipei noastre, ne puteti lasa un CV la sediul nostru

                                sau ne puteti contacta la numarul de telefon 307-8705.
                            </p>
                            <p></p>
                            <h4>Unde ne gasiti?</h4>
                    <p>Birourile Alta Street, Los Santos (fosta scoala de soferi)</p>
                   <center><img src="assets/img/locatie.png" alt=""/><p></p><h5>Tel: 307-8705</h5></center> 
                   
                        </div>
                    </div>
                    {/*
                    <div className="col-lg-4">
                        <div className='formular-audienta'>
                            {successMessage && <p>{successMessage}</p>}
                            <form onSubmit={handleSubmit}>
                                <div className="input-formular">
                                    <span> Nume: </span>
                                    <input type='text' name='nume' value={formData.nume} onChange={handleInputChange} />
                                </div>
                                <div className="input-formular">
                                    <span> Prenume: </span>
                                    <input type='text' name='prenume' value={formData.prenume} onChange={handleInputChange} />
                                </div>
                                <div className="input-formular">
                                    <span> CNP: </span>
                                    <input type='text' name='cnp' value={formData.cnp} onChange={handleInputChange} />
                                </div>
                                <div className="input-formular">
                                    <span> Telefon: </span>
                                    <input type='text' name='telefon' value={formData.telefon} onChange={handleInputChange} />
                                </div>
                                <button type="submit">Depune CV</button>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default CvContent;

import React from 'react'
import { Link } from 'react-router-dom'

function FooterThree() {
  return (
	
      <footer>
      
        <div className="copyright-wrap third-copyright-wrap">
          <div className="container custom-container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>Copyright © 2024 <Link to="/">Shelby</Link> All Rights Reserved.</p>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </footer>
  )
}

export default FooterThree
import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import axios from 'axios';

function AudientaContent() {
    const [date, setDate] = useState(new Date());
    const [formData, setFormData] = useState({
        nume: '',
        prenume: '',
        cnp: '',
        tipcontract: 'afacere',
        telefon: '',
    });
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const dataToSend = {
            ...formData,
            date,
        };

        axios.post('https://apidug.flum.ro:8089/api/form', dataToSend, { withCredentials: true })
            .then(response => {
                console.log('Form data sent successfully:', response.data);
                setSuccessMessage('Cererea a fost trimisă cu succes!');
                setFormData({
                    nume: '',
                    prenume: '',
                    cnp: '',
                    tipcontract: 'afacere',
                    telefon: '',
                });
                setDate(new Date());
            })
            .catch(error => {
                console.error('Error sending form data:', error);
            });
    };

    return (
        <section className="latest-match-area latest-match-bg pt-115 pb-90">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="section-title title-style-three white-title text-center mb-70">
                            <h2>Formular <span>Contract Paza</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                       
                        <p></p>
                        <img src="assets/img/servicii/contract.png" alt="" />
                    </div>
                    <div className="col-lg-4">
                        <p> </p>
                        <p> </p>
                    <div className="text-formular">
                            <h2>Contract de Servicii de Pază</h2>
                            <p>
                            Suntem aici pentru a vă oferi soluții de securitate personalizate. Pentru a solicita un contract de servicii de pază vă așteptam la sediul nostru de pe Alta Street (fosta scoală de șoferi) unde putem discuta detaliile și vă putem oferi o ofertă personalizată.
                            </p>
                        </div>
                    </div>
                    {/*
                    <div className="col-lg-4">
                        <div className='formular-audienta'>
                            {successMessage && <p>{successMessage}</p>}
                            <form onSubmit={handleSubmit}>
                                <div className="input-formular">
                                    <span> Nume: </span>
                                    <input type='text' name='nume' value={formData.nume} onChange={handleInputChange} />
                                </div>
                                <div className="input-formular">
                                    <span> Prenume: </span>
                                    <input type='text' name='prenume' value={formData.prenume} onChange={handleInputChange} />
                                </div>
                                <div className="input-formular">
                                    <span> CNP: </span>
                                    <input type='text' name='cnp' value={formData.cnp} onChange={handleInputChange} />
                                </div>
                                <div className="input-formular">
                                    <span> Tip contract: </span>
                                    <select name="tipcontract" value={formData.tipcontract} onChange={handleInputChange}>
                                        <option value="afacere">Paza Afacere</option>
                                        <option value="eveniment">Paza Eveniment</option>
                                        <option value="transport">Escorta Transport</option>
                                        <option value="bodyguard">Bodyguard</option>
                                    </select>
                                </div>
                                <div className="input-formular">
                                    <span> Telefon: </span>
                                    <input type='text' name='telefon' value={formData.telefon} onChange={handleInputChange} />
                                </div>
                                <div className="input-formular">
                                    <span> Data: </span>
                                    <Flatpickr
                                        data-enable-time
                                        value={date}
                                        onChange={(selectedDates) => setDate(selectedDates[0])}
                                        options={{ dateFormat: "Y-m-d H:i" }}
                                    />
                                </div>
                                <button type="submit">Trimitea cererea</button>
                            </form>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default AudientaContent;

import React from 'react';


function ServiciiContent() {
  
  return (
    
	 <section className="latest-match-area latest-match-bg pt-115 pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three white-title text-center mb-70">
                <h2>Serviciile <span>Duggano Security</span></h2>
                
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-lg-8" style={{ alignContent: 'center' }}>


       
            <div className="text-formular">
                <h2>Paza Umana</h2>
                <p>
                Concepem proceduri speciale pentru fiecare client în parte. Serviciile noastre sunt prin excelență customizate și adaptate la politicile și cerințele fiecărui client în parte. </p>
                <p>
                Am dat și dăm dovadă de exigență, fermitate și excelență.  
                </p>
                <p>
                Ne pregătim permanent și ne testăm oamenii. Clienții noștri au la dispoziție cei mai buni agenți de securitate de pe piață.
                </p>
                <p>
                Ca industrie, intermediem prima impresie a unui client, a unui colaborator sau a unui partener prin agenții dedicați beneficiarului. Ne ghidăm după acest principiu în fiecare etapă a procesului, de la pregătirea intensivă a echipei noastre până la dotarea cu echipament de securitate. Aducem această atitudine proactivă în fiecare interacțiune, construind relații de încredere încă de la început. Descoperă și serviciile de intervenție rapidă!
                </p>
            </div>
          </div>
            <div class="col-lg-4">
                   <img src="assets/img/servicii/pazaumana.png" alt=""/>
                </div>
              
            </div>

            <div className="row paddingtop">
                <div class="col-lg-4">
                <img src="assets/img/servicii/escorta.png" alt=""/>
                </div>
                <div className="col-lg-8" style={{ alignContent: 'center' }}>

       
            <div className="text-formular">
                <h2>Escorta Transport</h2>
                <p>
                Exista clienti in domeniul auto care efectueaza transport de marfuri cu o valoare deosebita. Punem la dispozitia acestor clienti servicii de escorta specializata pentru a reduce furturile si posibilitatea pierderii incarcaturii in timpul deplasarii. Duggano Security asigura paza bunurilor transportate pe parcursul traseului stabilit de client.
                </p>
            </div>
          </div>
            
              
            </div>

            <div className="row paddingtop">
               
            <div className="col-lg-8" style={{ alignContent: 'center' }}>

       
            <div className="text-formular">
                <h2>Bodyguard</h2>
                <p>
                Asigurarea deplasarii persoanelor implicate in siguranta indiferent de imprejurari sau situatii </p>
                <p>Asigurarea si securizarea perimetrului in care persoana implicata se deplaseaza </p>
                <p>Asigurarea legaturii cu serviciile medicale in caz de necesitate
                </p>
            </div>
          </div>
            
               <div class="col-lg-4">
               <img src="assets/img/servicii/bodyguard.png" alt=""/>
                </div>
            </div>
            <div className="row paddingtop">
                 <div class="col-lg-4">
                 <img src="assets/img/servicii/evenimente.png" alt=""/>
                </div>
                <div className="col-lg-8" style={{ alignContent: 'center' }}>

       
            <div className="text-formular">
                <h2>Serviciile de Securitate oferite in cadrul Evenimentelor</h2>
                <p>Desfasurarea evenimentelor in conditii de siguranta reprezinta o provocare atat pentru organizatori, cat si pentru furnizorii de servicii de securitate. Punerea in aplicare a fiecarui detaliu de securitate sunt principalele preocupari ale echipei de profesionisti Duggano Security.</p>
            </div>
          </div>
            
             
            </div>
        </div>
      </section>
  )
}

export default ServiciiContent
import React from 'react'
function GamesAreatwo() {

  
  return (
	 <section className="just-gamers-area just-gamers-bg pt-115 pb-120">
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-10">
                  <div className="section-title title-style-three white-title mb-70">
                   <h3>Valorile Duggano Security </h3>
                    <p>Cele trei valori fundamentale ale Duggano Security sunt: Integritate, Vigilenta si Ajutor. Ele calauzesc angajatii nostri in fundamentarea increderii fata de clienti, colegi si comunitate.</p>
                  </div>
                  <div className="just-gamers-list">
                    <ul>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/icon/gamer_list_icon01.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Integritate</h5>
                          <p>Un angajat Duggano Security este onest, pentru acest motiv prezinta incredere si poate lucra fara a fi supervizat in obiectivele si cu valorile clientului. Duggano Security nu face niciodata compromisuri in ceea ce priveste exigentele sale legate de integritate. Integritatea include ascultarea opiniilor, raportarea neregulilor, fara a se omite nicio informatie. </p>  </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/icon/gamer_list_icon02.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Vigilenta</h5>
                          <p>Vigilenta necesita observare, informare si evaluare. Un angajat Duggano Security este intotdeauna devotat si observa, de regula, situatii pe care altii nu le remarca. Vigilenta personalului este necesara pentru a constientiza un potential risc sau incident care poate avea loc in obiectivele clientilor nostri.</p>
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/icon/gamer_list_icon03.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Ajutor</h5>
                          <p>Cand este nevoie, un angajat Duggano Security va oferi asistenta, chiar daca situatia respectiva nu il implica in mod direct sau nu intra in atributiile sale. Ca parte a efortului de a mentine un aspect de normalitate, un angajat Duggano Security se va implica intotdeauna in acordarea de asistenta in cazul aparitiei unui incident pentru a carei solutionare este nevoie de interventie.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 d-none d-lg-block agentaligncenter">
                  <div className="just-gamers-img">
                    <img src="assets/img/images/just_gamers_img.png" alt="" className="just-gamers-character" />
                    <div className="just-gamers-circle-shape">
                      <img src="assets/img/images/gamers_circle_line.png" alt="" />
                      <img src="assets/img/images/gamers_circle_shape.png" alt="" className="rotateme" />
                    </div>
                    <img src="assets/img/images/just_gamers_chart.png" alt="" className="gamers-chart-shape" />
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default GamesAreatwo
import React from 'react';
import HeaderThree from '../../../components/header/HeaderThree';
import FooterThree from '../../../components/footer/FooterThree';
import LatestMatch from './LatestMatch'
import GamesAreatwo from '../../../components/index/indextwo/GamesAreatwo'

function AboutUs() {
  return (
	<>
	<HeaderThree/>
	<main>
	

	<LatestMatch/>
	<GamesAreatwo/>
	</main>
	<FooterThree/>
	</>
  )
}

export default AboutUs

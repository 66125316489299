import { BrowserRouter as Router, Switch} from 'react-router-dom';

import HomeThree from './pages/homes/HomeThree';
import Contact from './pages/contact/Contact';
import AboutUs from './pages/aboutpage/aboutuspage/AboutUs';
import Audienta from './pages/audienta/audienta';
import Servicii from './pages/servicii/servicii';
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import HomeTwo from './pages/homes/HomeTwo';
import Galerie from './pages/galerie/galerie';
import Cv from './pages/cv/cv';
import Evenimente from './pages/evenimente/evenimente';
// import Loader from "react-js-loader";





function App() {
      //  const [loading,setLoading] = useState(false);

      //  useEffect(()=>{
      //   setLoading(true)
      //   setTimeout(()=>{
      //     setLoading(false)
      //   },8000)
      //  },[])
      //  {
      //   loading ?(
      //     <MoonLoader color={color} loading={loading} css={override} size={150} />
      //   )
         
  
      // }
  return (
    
    <div className="App">
      
     <Router>
       
        <Switch>
        
      
        <ScrollToTopRoute exact={true} path='/galerie'>
        <Galerie/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/'>
        <HomeThree/>
        </ScrollToTopRoute>
       
        <ScrollToTopRoute exact={true} path='/servicii'>
        <Servicii/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/contact'>
        <Contact/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/cerere'>
        <Audienta/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/despre-noi'>
        <AboutUs/>
        </ScrollToTopRoute>
        
      
        <ScrollToTopRoute exact={true} path='/depunere-cv'>
        <Cv/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/evenimente'>
        <Evenimente/>
        </ScrollToTopRoute>
     
      

        
     </Switch>
     </Router>
     
    </div>
  );
}

export default App;

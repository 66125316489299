import React from 'react';
import HeaderThree from '../../components/header/HeaderThree';
import FooterThree from '../../components/footer/FooterThree';
import AudientaContent from './audienta-content';


function Audienta() {
  return (
	<>
	<HeaderThree/>
	<main>
	<AudientaContent/>



	</main>
	<FooterThree/>
	</>
  )
}

export default Audienta

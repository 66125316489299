import React from 'react'
import Bounce from 'react-reveal/Bounce';

function SliderThree() {
  return (
	 <section className="third-banner-bg">
            <div className="container custom-container">
              <div className="row">
                <div className="col-12">
                  <div className="third-banner-img wow bounceInDown" data-wow-delay=".2s">
                   <Bounce top>
                   <img src="assets/img/slider/slider1.png" alt="" />
                   </Bounce>
                  </div>
                  <div className="third-banner-content text-center wow bounceInUp" data-wow-delay=".2s">
                    <Bounce bottom>
                    <h2>Duggano <span>Security</span></h2>
                    <h6>make your business safe</h6>
                    <a href="/servicii" className="btn rotated-btn">SERVICII OFERITE</a>
                    </Bounce>
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default SliderThree
import React from 'react';
import HeaderThree from '../../components/header/HeaderThree';
import FooterThree from '../../components/footer/FooterThree';
import CvContent from './cv-content';


function Cv() {
  return (
	<>
	<HeaderThree/>
	<main>
	<CvContent/>



	</main>
	<FooterThree/>
	</>
  )
}

export default Cv

import React from 'react'
import FooterThree from '../../footer/FooterThree';
import HeaderThree from '../../header/HeaderThree';
import SliderThree from './SliderThree'

function IndexThree() {
  return (
	  <>
	  	{/* header-area */}
      		<HeaderThree/>
        {/* header-area-end */}
        {/* main-area */}
        <main>
          {/* slider-area */}
          	<SliderThree/>
      
        </main>
    
			<FooterThree/>
		</>
		
  )
}

export default IndexThree
import React from 'react';
import HeaderThree from '../../components/header/HeaderThree';
import FooterThree from '../../components/footer/FooterThree';
import ServiciiContent from './servicii-content';


function Servicii() {
  return (
	<>
	<HeaderThree/>
	<main>
	<ServiciiContent/>



	</main>
	<FooterThree/>
	</>
  )
}

export default Servicii
